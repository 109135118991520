import { graphql } from "gatsby"
import React from "react"
import SEO from "../../components/seo.component"
import Layout from "../../components/layout"
import { Menu } from "../../navigation/types/menu.interface"
import { AppFunctionComponent, TextBuilder } from "../../types"
import CookiePolicyScript from "../../components/common/CookiePolicyScript"

type PrivacyProps = {
  data: {
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }
}

const PrivacyPolicy: AppFunctionComponent<PrivacyProps> = ({
  data: {
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => {
  return (
    <Layout
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
      promobarContent={promobarContent}
      withoutFooter
    >
      <SEO title="Privacy Policy Cookies" />
      <CookiePolicyScript />
    </Layout>
  )
}

export const query = graphql`
  query PrivacyQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: "en-US" }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: "en-US" }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: "en-US" }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`

export default PrivacyPolicy
